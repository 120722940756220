<template>
  <div>
    <!--<div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Tabulator</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button class="btn btn-primary shadow-md mr-2">Add New Product</button>
        <div class="dropdown ml-auto sm:ml-0">
          <button
              class="dropdown-toggle btn px-2 box text-gray-700 dark:text-gray-300"
              aria-expanded="false"
          >
            <span class="w-5 h-5 flex items-center justify-center">
              <PlusIcon class="w-4 h-4" />
            </span>
          </button>
        </div>
      </div>
    </div>-->
    <div class="grid grid-cols-1 gap-12">
			<div class="intro-y box p-5 mt-5">
        <Toast />
				<DataTable :value="services" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                        :rows="10" dataKey="id" :rowHover="true" v-model:selection="selectedServices" v-model:filters="filters" :loading="loading">
					<template #header>
						<div class="table-header">
							Servicios
							<span class="p-input-icon-left">
							<i class="pi pi-search"/>
							<InputText v-model="filters['global'].value" placeholder="Search"/>
						</span>
						</div>
					</template>

					<template #empty>
						Servicios no disponibles.
					</template>
					<template #loading>
            Cargando datos de servicios. Por favor espera.
					</template>

					<Column field="solicitudServicio" header="Solicitud" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Solicitud</span>
							{{slotProps.data.solicitudServicio}}
						</template>
					</Column>
          <Column field="cliente" header="Cliente" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Cliente</span>
              {{slotProps.data.cliente}}
            </template>
          </Column>
          <Column field="tipoServicio" header="Tipo de servicio" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Tipo de servicio</span>
              {{slotProps.data.tipoServicio}}
            </template>
          </Column>
          <Column field="ciudadOrigen" header="Ciudad de origen" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Ciudad de origen</span>
              {{slotProps.data.ciudadOrigen}}
            </template>
          </Column>
          <Column field="ciudadDestino" header="Ciudad de destino" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Ciudad de destino</span>
              {{slotProps.data.ciudadDestino}}
            </template>
          </Column>
          <Column field="licitacionFleteValor" header="Valor flete" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Valor flete</span>
              {{slotProps.data.licitacionFleteValor}}
            </template>
          </Column>
					<Column headerStyle="width: 8rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
            <template #body="slotProps">
              <div class="flex items-center justify-center">
                <a class="flex items-center mr-3" href="javascript:;" @click="showService(slotProps.data)">
                  <InfoIcon class="w-6 h-6 mr-4" />
                </a>
                <a class="flex items-center mr-3 text-theme-10" href="javascript:;" @click="showDialog(slotProps.data)">
                  <CheckSquareIcon class="w-6 h-6 mr-4" />
                </a>
              </div>
						</template>
					</Column>
				</DataTable>
      </div>

      <FormSilogtran :service="service"  :loading="loading" v-if="serviceShow"  />

      <Dialog v-model:visible="serviceDialog" :style="{width: '450px'}" header="Aceptar solicitud de servicio" :modal="true" class="p-fluid">
        <div class="p-field">
          <label for="solicitudServicioSend">Solicitud de servicio</label>
          <InputText id="solicitudServicioSend" readonly="true" v-model.trim="service.solicitudServicio" required="true" autofocus :class="{'p-invalid': submitted && !service.solicitudServicio}" />
          <small class="p-invalid" v-if="submitted && !service.solicitudServicio">Solicitud de servicio es requerida.</small>
        </div>
        <div class="p-field">
          <label for="observation">Observación</label>
          <Textarea id="observation" v-model="observationService" required="true" rows="3" cols="20" />
        </div>

        <template #footer>
          <button class="btn btn-outline-danger w-24 inline-block mr-1 mb-2" @click="hideDialog">Cancelar</button>
          <button class="btn btn-outline-primary w-24 inline-block mr-1 mb-2" @click="acceptService">Aceptar Solicitud</button>
        </template>
      </Dialog>

    </div>
  </div>


</template>

<script>
	import OrderService from '../service/OrderService';
  import axios from "axios";
  import {API_BASE_URL, API_LOCAL_URL} from "@/config";
  import FormSilogtran from "@/components/forms/FormSilogtran";
  import {FilterMatchMode} from 'primevue/api';

	export default {
    components: {
      FormSilogtran
    },
    watch: {
      scopes: function (newVal){
        if(newVal) {
          const scopes = newVal.split(",");
          this.redirect(scopes);
        }
      }
    },
		data() {
			return {
				filters: {
          'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        },
				loading: true,
				services: [],
        selectedServices: null,
        serviceShow: false,
        serviceDialog: false,
        service: {},
				expandedRows: [],
        submitted: false,
        i: null,
        observationService: null,
        token: null
			}
		},
		orderService: null,
		created() {
      if(this.$store.state.auth.scopes) {
        const scopes = this.$store.state.auth.scopes.split(",");
        this.redirect(scopes);
      }
			this.orderService = new OrderService();
			var cssId = 'cssLogin';
			var css = document.getElementById(cssId);
			if (css){
				let padre = css.parentNode;
				padre.removeChild(css);
			}
		},
		async mounted() {
      this.token = await this.generateToken();
      this.orderService.getOrders(this.token).then(data => {
        this.services = Object.values(data);
        this.loading = false;
      })
          .catch(err => {
            console.log(err.response.data.msg);
            this.$toast.add({
              severity: 'error',
              summary: 'Error al traer los datos',
              detail: err.response.data.msg,
              life: 5000
            });
            this.loading = false;
          });
    },
		methods: {
      redirect(scopes){
        for (var key in scopes) {
          switch (scopes[key]) {
            case 'Otros':
              this.$router.push({ name: "generateorder2" });
              break;
            case 'Operaciones':
            case 'Trafico':
            case 'Otm':
              this.$router.push({ name: "generatedorders" });
              break;
            case 'Bdp':
              this.$router.push({ name: "orders-bdp" });
              break;
          }
        }
      },
      showService(service) {
        if(this.service.solicitudServicio == service.solicitudServicio){
          this.serviceShow = false;
          this.service = {};
        } else {
          this.serviceShow = false;
          this.loading = true;
          setTimeout(() => this.serviceShow = true, 100);
          setTimeout(() => this.loading = false, 100);
          this.service = {...service};
        }
      },
      showDialog(service) {
        this.serviceDialog = true;
        this.submitted = false;
        this.service = {...service};
      },
      async acceptService(){
        this.submitted = true;
        this.serviceDialog = false;
        this.loading = true;

        if (this.service.solicitudServicio.trim() && this.observationService.trim()) {
          if(this.services[this.findIndexById(this.service.solicitudServicio)]){

            var axios1, axios2, axios3;
            this.token = await this.generateToken();

            const config = {
              headers: {
                "Authorization": this.token,
                "Content-Type": "application/json",
                "Accept": "application/json",
              }
            };
            const formDataAcept = {
              solicitudServicio: this.service.solicitudServicio,
              aceptaSolicitud: "SI",
              observacion: this.observationService
            };

            axios1 = await new Promise((resolve)=>{
              let url = API_BASE_URL.replace('{replace}', 'api=Servicio.GeneradorCargaDsv.aceptacionSolicitudServicio');
              axios
                  .post(url, formDataAcept, config)
                  .then(response => {
                    resolve(response);
                  })
                  .catch(err => {
                    console.log(err.response.data.msg);
                    this.$toast.add({severity:'error', summary: 'Error de solicitud', detail:err.response.data.msg, life: 5000});
                  });
            });

            if (axios1.data.success){
              axios2 = await new Promise((resolve)=>{
                this.postApiLocal('general', this.service)
                    .then(response => {
                      resolve(response);
                    })
                    .catch(() => {
                      this.$toast.add({severity:'error', summary: 'Error de solicitud', detail:'No se ha podido procesar la solicitud', life: 5000});
                    });
              });
              var bandera = [
                  false,
                  false,
                  false
              ];
              axios3 = await new Promise((resolve)=>{
                for (const i in this.service.info_entregas) {
                  this.service.info_entregas[i]['solicitud'] = axios2.data.data.solicitudServicio;
                  this.service.info_entregas[i]['info_entrega'] = i;
                  this.postApiLocal('entrega', this.service.info_entregas[i])
                      .then(response => {
                        var datan = JSON.parse(response.config.data);

                        //Contenedores
                        for (let j in this.service.info_entregas[datan.info_entrega].contenedor) {
                          this.service.info_entregas[datan.info_entrega].contenedor[j]['idEntrega'] = response.data.data.id;
                          this.postApiLocal('contenedor', this.service.info_entregas[datan.info_entrega].contenedor[j])
                              .then(() => {
                                bandera[0] = true;
                              })
                              .catch(() => {
                                this.$toast.add({severity:'error', summary: 'Error de contenedor', detail:'No se ha podido procesar la solicitud', life: 5000});
                              });
                        }

                        //Mercancias
                        for (let k in this.service.info_entregas[datan.info_entrega].mercancia) {
                          this.service.info_entregas[datan.info_entrega].mercancia[k]['idEntrega'] = response.data.data.id;
                          this.postApiLocal('mercan', this.service.info_entregas[datan.info_entrega].mercancia[k])
                              .then(() => {
                                bandera[1] = true;
                              })
                              .catch(() => {
                                this.$toast.add({severity:'error', summary: 'Error de mercancia', detail:'No se ha podido procesar la solicitud', life: 5000});
                              });
                        }

                        //Servicios adicionales
                        for (let l in this.service.info_entregas[datan.info_entrega].servicioAdicional) {
                          this.service.info_entregas[datan.info_entrega].servicioAdicional[l]['idEntrega'] = response.data.data.id;
                          this.postApiLocal('adicional', this.service.info_entregas[datan.info_entrega].servicioAdicional[l])
                              .then(() => {
                                bandera[2] = true;
                              })
                              .catch(() => {
                                this.$toast.add({severity:'error', summary: 'Error de servicio adicional', detail:'No se ha podido procesar la solicitud', life: 5000});
                              });
                        }

                        resolve(bandera);
                      })
                      .catch(() => {
                        this.$toast.add({severity:'error', summary: 'Error de entrega', detail:'No se ha podido procesar la solicitud', life: 5000});
                      });
                }
              });
              Promise.all([axios1, axios2, axios3]).then(() => {
                this.services = this.services.filter(val => val.solicitudServicio !== this.service.solicitudServicio);
                this.loading = false;
                this.service = {};
                this.$toast.add({severity:'success', summary: 'Exito', detail: axios1.data.data, life: 5000});
                //this.$toast.add({severity:'success', summary: 'Exito', detail: 'Exito', life: 5000});
              });
            } else {
              this.$toast.add({severity:'error', summary: 'Error de solicitud', detail:'No se ha podido procesar la solicitud', life: 5000});
            }
          }else{
            this.$toast.add({severity:'error', summary: 'Error', detail:'Solicitud de servicio no coincide', life: 5000});
          }
        }
      },
      hideDialog() {
        this.serviceDialog = false;
        this.submitted = false;
      },
      findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.services.length; i++) {
          if (this.services[i].solicitudServicio === id) {
            index = i;
            break;
          }
        }

        return index;
      },
      postApiLocal(slug, data){
        const config = {
          headers: {
            "Authorization": "Bearer " + this.$store.state.auth.token,
            "Content-Type": "application/json",
            "Accept": "application/json",
          }
        };
        return axios
            .post(API_LOCAL_URL+slug, data, config);
      },
      generateToken(){
        const config = {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
          }
        };
        const formData = {
          usuario_login: 'WEB.SERVICESARVI',
          usuario_password: "Sarviweb2021"
        };
        return new Promise((resolve) => {
          let url = API_BASE_URL.replace('{replace}', 'api=Servicio.Seguridad.login', config);
          axios
              .post(url, formData)
              .then(response => {
                if (response.data.success) {
                  resolve(response.data.data.token);
                } else {
                  this.$toast.add({
                    severity: 'error',
                    summary: 'No se ha podido iniciar sesión',
                    detail: 'No se ha podido iniciar sesión',
                    life: 5000
                  });
                }
              })
              .catch(err => {
                console.log(err.response.data.msg);
                this.$toast.add({
                  severity: 'error',
                  summary: 'No se ha podido iniciar sesión',
                  detail: err.response.data.msg,
                  life: 5000
                });
              });
        });
      }
		},
    computed: {

      scopes() {
        return this.$store.state.auth.scopes;
      }

    }
	}
</script>

<style scoped lang="scss">
.p-component{
  font-size: 0.875rem !important;
}

::v-deep(.p-progressbar) {
	height: .5rem;
	background-color: #D8DADC;

	.p-progressbar-value {
		background-color: #607D8B;
	}
}

.p-datatable .p-column-filter {
	display: none;
}

.table-header {
	display: flex;
	justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
	.p-datatable-header {
		padding: 1rem;
		text-align: left;
		font-size: 1.5rem;
	}

	.p-paginator {
		padding: 1rem;
	}

	.p-datatable-thead > tr > th {
		text-align: left;
	}

	.p-datatable-tbody > tr > td {
		cursor: auto;
	}

	.p-dropdown-label:not(.p-placeholder) {
		text-transform: uppercase;
	}
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

.customer-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-qualified {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-unqualified {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-negotiation {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.status-new {
		background: #B3E5FC;
		color: #23547B;
	}

	&.status-renewal {
		background: #ECCFFF;
		color: #694382;
	}

	&.status-proposal {
		background: #FFD8B2;
		color: #805B36;
	}
}

.p-progressbar-value.ui-widget-header {
	background: #607d8b;
}

@media (max-width: 640px) {
	.p-progressbar {
		margin-top: .5rem;
	}
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
	padding: 1rem;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.order-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.order-delivered {
		background: #C8E6C9;
		color: #256029;
	}

	&.order-cancelled {
		background: #FFCDD2;
		color: #C63737;
	}

	&.order-pending {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.order-returned {
		background: #ECCFFF;
		color: #694382;
	}
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}
}
</style>
