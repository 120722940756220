import axios from 'axios';
import { API_BASE_URL, API_BASE_BDP_URL } from "@/config";
export default class OrderService {

    getOrders(token) {
      const config = {
        headers: {
          "Authorization": token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      };
      let url = API_BASE_URL.replace('{replace}', 'api=Servicio.GeneradorCargaDsv.consultarSolicitudServicioDisponibles');
          return axios.post(url,'',config).then(res => res.data.data);
      //return axios.post('https://panalpina.colombiasoftware.net/images/imagenes/panalpina/webservice/panalpinaarquitecturarest/consultarSolicitudServicioDisponiblesEstructuraJSON.json').then(res => res.data.data);
    }

    getOrdersBdp(token) {
      const config = {
        headers: {
          "Authorization": token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      };
      let url = API_BASE_BDP_URL.replace('{replace}', 'api=Servicio.GeneradorCargaBdp.consultarSolicitudServicioDisponibles');
      return axios.post(url,'',config).then(res => res.data.data);
      //return axios.post('https://panalpina.colombiasoftware.net/images/imagenes/panalpina/webservice/panalpinaarquitecturarest/consultarSolicitudServicioDisponiblesEstructuraJSON.json').then(res => res.data.data);
    }

}